import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { TbArrowBigRight } from "react-icons/tb"

export default function Web() {
  return (
    <Layout pageTitle="Web Design & Development">
      <section className="reading web">
        <h1>Web Design & Development</h1>

        <p>
          I have 20+ years experience with designing and developing for web. As much as possible, I design for accessibilty and mobile. In the past I've mostly worked with popular Content Management Systems (WordPress, Drupal) and pure-code HTML, CSS, JavaScript site. These days I prefer to work
          with static site builders such as Eleventy, Gatsby, or NextJS. I like to make editor workflows and site maintenance as easy as possible. Please see a colleciton of some sites I've made:
        </p>

        <article>
          <section className="main">
            <h2>Lotus Wight</h2>
            <p>An bare-bones bilingual site I made for a folk musician I admire. Made with 11ty.</p>
            <Link to="https://lotuswight.com" className="button">
              lotuswight.com
              <TbArrowBigRight />
            </Link>
          </section>
          <StaticImage className="screenshot" objectFit="contain" src="../images/lw-site.png" alt="Lotus Wight site" />
        </article>

        <article>
          <section className="main">
            <h2>Improvised Music in Kingston</h2>
            <p>An 11ty site for my MA in Cultural Studies at Queen's University.</p>
            <Link to="https://imik.netlify.app/" className="button">
              imik.netlify.app
              <TbArrowBigRight />
            </Link>
          </section>
          <StaticImage className="screenshot" objectFit="contain" src="../images/imik-site.png" alt="IMIK site" />
        </article>

        <article>
          <section className="main">
            <h2>Back Stick Electric</h2>
            <p>This simple business card site was made using Gatsby and is hosted on Netlify. I use this combo quite often since it's super fast, hosting is next to free, and it's really fun to develop.</p>
            <Link to="https://www.backstick.ca/" className="button">
              backstick.ca
              <TbArrowBigRight />
            </Link>
          </section>
          <StaticImage className="screenshot" objectFit="contain" src="../images/backstick-site.png" alt="Back Stick site" />
        </article>
        <article>
          <section className="main">
            <h2>The Gertrudes</h2>
            <p>A one-pager for a great local band (who I also play with sometimes). This was a port from a WordPress site into Gatsby.</p>
            <Link to="https://thegertrudes.com/" className="button">
              thegertrudes.com
              <TbArrowBigRight />
            </Link>
          </section>
          <StaticImage className="screenshot" objectFit="contain" src="../images/gertrudes-site.png" alt="The Gertrudes site" />
        </article>
        <article>
          <section className="main">
            <h2>Skeleton Park Arts Festival</h2>
            <p>
              A team-effort WordPress site for SPAF! I've worked with some awesome folks on this one over the years like <Link to="">Wendy Huot</Link>, <Link to="https://www.everlovinpress.com/">Vince Perez</Link>, and <Link to="https://bentknee.ca/">Neil Bettney</Link>. It's an awesome party if
              you've never been; come check it out sometime.
            </p>
            <Link to="https://skeletonparkartsfest.ca/" className="button">
              skeletonparkartsfest.ca
              <TbArrowBigRight />
            </Link>
          </section>
          <StaticImage className="screenshot" objectFit="contain" src="../images/spaf-site.png" alt="SPAF site" />
        </article>

        <article>
          <section className="main">
            <h2>Songbook</h2>
            <p>A book of tunes for jams. Built using Gatsby and ChordSheetJS. It's got built-in search, autoscroll, and you can transpose the songs. Will always be a Work-In-Progress.</p>
            <Link to="https://songbook.netlify.app/" className="button">
              songbook.netlify.app
              <TbArrowBigRight />
            </Link>
          </section>
          <StaticImage className="screenshot" objectFit="contain" src="../images/songbook-site.png" alt="Songbook site" />
        </article>
      </section>
    </Layout>
  )
}
